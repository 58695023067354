<nb-layout>
  <nb-layout-column>
    <nb-card style="height: 80vh;">
      <nb-card-header>Login</nb-card-header>
      <nb-card-body class="">
        <div class="container">
          <section class="section register  d-flex flex-column align-items-center justify-content-center py-4">
            <div class="container">
              <div class="row justify-content-center">
                <div class="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">


                  <div class="card mb-3">
                    <div class="card-body">
                      <div class="pt-4 pb-2">
                        <h5 class="card-title text-center pb-0 fs-4">
                          Login to Your Account
                        </h5>
                        <p class="text-center small">
                          Enter your username & password to login
                        </p>
                        <p class="text-danger">
                          {{message}}
                        </p>
                       
                      </div>

                      <form class="row g-3 needs-validation" (ngSubmit)="onSubmit($event)" noValidate>
                        <div class="col-12 ">
                          <label htmlFor="email" class="form-label">
                            Email
                          </label>
                          <div class="input-group has-validation">
                            <span class="input-group-text" id="inputGroupPrepend">
                              @
                            </span> 
                            <input  [(ngModel)]="email"  name="email" id="email" type="email" class="form-control" required />
                            <div class="invalid-feedback">
                              Please enter your username.
                            </div>
                          </div>
                        </div>

                        <div class="col-12">
                          <label htmlFor="password" class="form-label mt-3">
                            Password
                          </label>
                          <input    [(ngModel)]="password"  name="password" id="password" type="password" class="form-control" required />
                          <div class="invalid-feedback">
                            Please enter your password!
                          </div>
                        </div>

                        <div class="col-12 mt-3">
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox" name="remember" value="true"
                              id="rememberMe" />
                            <label class="form-check-label" password="rememberMe">
                              Remember me
                            </label>
                          </div>
                        </div>
                        <div class="col-12">
                          <button class="btn btn-primary w-100 mt-5" type="submit">
                            Login
                          </button>
                        </div>

                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </nb-card-body>
    </nb-card>
  </nb-layout-column>
</nb-layout>