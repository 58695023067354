<div class="col-md-12 ">
    <nb-card class="list-card" size="small">
      <nb-card-header>Notifications</nb-card-header>
      <nb-list>
        <nb-list-item *ngFor="let user of users">
       <div class="row w-100">
        <div class="col-9">
            <nb-user [name]="user.name" [title]="user.title">
            </nb-user>
        </div>
        <div class="col-3 d-flex align-items-end justify-content-end" style="font-size: 12px;flex: 1;">
         {{user.date}}
        </div>
       </div>
        </nb-list-item>
      </nb-list>
    </nb-card>
  </div>

