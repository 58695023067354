// src/app/services/axios.service.ts
import { Injectable } from '@angular/core';
import axios from 'axios';

@Injectable({
  providedIn: 'root',
})
export class AxiosInstanceService {
  axiosInstance = axios.create({
    baseURL:  'https://api.confusability.ai/api/admin/', // Fallback URL if the environment variable is not set
    // baseURL:  'http://localhost:3021/api/admin', // Fallback URL if the environment variable is not set
   
  });

  constructor() {
    this.initializeInterceptors();
    this.setDefaultHeaders();
  }

  initializeInterceptors() {
    this.axiosInstance.interceptors.request.use(
      (config) => {
        const token = localStorage.getItem('access_token');
        console.log('instance',token)
        if (config.method !== 'post' && token && (config.url.includes('api') || config.method === 'get')) {
          const separator = config.url.includes('?') ? '&' : '?';
          config.url += `${separator}token=${encodeURIComponent(token)}`;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      },
    );

    this.axiosInstance.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && (error.response.status === 401 || error.response.status === 403)) {
          
          // if(){
          //   
          // }
          // Handle 401 errors, possibly by dispatching actions or redirecting

          this.logout()
          console.log('Session timeout or unauthorized. Handling accordingly.');
          // Optionally dispatch logout action or handle session timeout
        }
  
        return Promise.reject(error);
      },
    );
  }
  logout() {
    localStorage.removeItem('access_token')
  }
  setDefaultHeaders() {
    const token = localStorage.getItem('access_token');
    const sessionID = localStorage.getItem('sessionID');

    if (token) {
      this.axiosInstance.defaults.headers.common['Authorization'] = `${token}`;
    }
    if (sessionID) {
      this.axiosInstance.defaults.headers.common['SessionID'] = sessionID;
    }
  }
}
