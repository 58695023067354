import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataRequestsComponent } from './data-requests.component';
import { NbCardModule, NbIconModule, NbInputModule, NbTreeGridModule } from '@nebular/theme';
import { ThemeModule } from '../../../@theme/theme.module';
import { Ng2SmartTableModule } from 'ng2-smart-table';



@NgModule({
  declarations: [
    DataRequestsComponent
  ],
  imports: [
    NbCardModule,
    NbTreeGridModule,
    NbIconModule,
    NbInputModule,
    ThemeModule,
    Ng2SmartTableModule,
    NbCardModule,
    CommonModule
  ],
})

export class DataRequestsModule { }
