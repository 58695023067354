import { Component, OnInit } from '@angular/core';
import { RequestsService } from '../../../@core/services/requests.service';

@Component({
  selector: 'ngx-data-requests',
  templateUrl: './data-requests.component.html',
  styleUrls: ['./data-requests.component.scss']
})
export class DataRequestsComponent implements OnInit {

  constructor(private requestDataService:RequestsService) { }
  data:any
  settings = {
    actions: {
      add: false,
      edit: false,
      delete: false,
      },


    columns: {
      id:{
        title:'userID',
        type:'string'
      },
      firstName:{
        title:'firstName',
        type:'string'
      },
      email:{
        title:'email',
        type:'string'
      },
    
      createdAt:{
        title:'date',
        type:'date'
      }
    },
  };
  ngOnInit(): void {
    this.requestDataService.getDataRequests().then((data)=>{
   
      let newData = data.data.map((res)=>(
        {...res,...res.userID}
      ))
      console.log(newData)
      this.data=newData
    })
  }

}
