import { Injectable } from '@angular/core';
import axios from 'axios';
import { url } from './services';
import { AxiosInstanceService } from './axios-instance.service';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionsService {
  getDataSubscriptions() {
    return new Promise<any>((resolve, reject) => {
      let Axios = axios.create({
        baseURL: url,
        timeout: 45000,
      });

      this.axiosInstanceService.axiosInstance.post("/user-subscriptions", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Credentials": "true",
        },
      })
        .then((e) => {
          resolve(e);
        })
        .catch((e) => {
          resolve(e);
        });
    });
  }
  constructor(private axiosInstanceService:AxiosInstanceService) { 
    
  }
}
