import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import {
  NbSortDirection,
  NbSortRequest,
  NbTreeGridDataSource,
  NbTreeGridDataSourceBuilder,
} from "@nebular/theme";
import { LocalDataSource } from "ng2-smart-table";
import { ActionsService } from "../../../../@core/services/actions.service";
import { SearchesService } from "../../../../@core/services/searches.service";
import { UsersService } from "../../../../@core/services/users.service";
import { title } from "process";
import { WatchesService } from "../../../../@core/services/watches.service";

interface TreeNode<T> {
  data: T;
  children?: TreeNode<T>[];
  expanded?: boolean;
}

interface FSEntry {
  name: string;
  size: string;
  kind: string;
  items?: number;
}

@Component({
  selector: "ngx-search-details",
  templateUrl: "./search-details.component.html",
  styleUrls: ["./search-details.component.scss"],
})
export class SearchDetailsComponent implements OnInit {
  selectedSearch: any;
  selectedWatch: any;
  // customColumn = 'type';
  defaultColumns = ["_id", "date", "imageURL"];
  dataSourceActions: any;
  // allColumns = [ this.customColumn, ...this.defaultColumns ];
  allColumns = [...this.defaultColumns];
  allData: TreeNode<FSEntry>[];
  dataSource: NbTreeGridDataSource<FSEntry>;
  dataWatchSource: NbTreeGridDataSource<FSEntry>;
  dataSource2: NbTreeGridDataSource<FSEntry>;
  sortColumn: string;
  sortDirection: NbSortDirection = NbSortDirection.NONE;

  constructor(
    private service: UsersService,
    private searchesService: SearchesService,
    private actionService: ActionsService,
    private dataSourceBuilder: NbTreeGridDataSourceBuilder<FSEntry>,
    private watchService: WatchesService,
    private userService: UsersService,
    private router: Router
  ) {
    this.dataSource = this.dataSourceBuilder.create(this.data);
    this.dataSource2 = this.dataSourceBuilder.create(this.data);
  }
  userData: any;
  userID: any;
  data: any;
  watchData: any;
  data2: any;
  settings = {
    actions: {
      add: false,
      edit: false,
      delete: false,
      custom: [
        {
          name: "export",
          type: "html",
          title:
            '<div class="custom-export-btn"><i class="nb-compose"></i></div>',
        },
      ],
    },
    pager: {
      display: true,
      perPage: 4,
    },
    columns: {
      _id: {
        title: "ID",
        type: "string",
      },
      label: {
        title: "title",
        type: "string",
      },
      date: {
        title: "Date",
        type: "string",
      },
      imageURL: {
        title: "imageURL",
        type: "html",
        valuePrepareFunction: (picture: string) => {
          return `<img width="100px" src="${picture}" />`;
        },
      },
    },
  };

  watchSettings = {
    actions: {
      add: false,
      edit: false,
      delete: false,
      custom: [
        {
          name: "export",
          type: "html",
          title:
            '<div class="custom-export-btn"><i class="nb-compose"></i></div>',
        },
      ],
    },
    pager: {
      display: true,
      perPage: 4,
    },
    columns: {
      _id: {
        title: "ID",
        type: "string",
      },
      label: {
        title: "title",
        type: "string",
      },
      subscription: {
        title: "subscription type",
        type: "string",
        valuePrepareFunction: (data: any) => {
          return data.type;
        },
      },
      
      createdAt: {
        title: "Date",
        type: "string",
      },
      
      imageURL: {
        title: "imageURL",
        type: "html",
        valuePrepareFunction: (picture: string) => {
          return `<img width="100px" src="${picture}" />`;
        },
      },
    },
  };
  settings2 = {
    actions: {
      add: false,
      edit: false,
      delete: false,
    },
    pager: {
      display: true,
      perPage: 7,
    },
    columns: {
      logsToPhrase: {
        title: "Logs",
        type: "string",
      },
     
      createdAt: {
        title: "date",
        type: "string",
      },
    },
  };

  source: LocalDataSource = new LocalDataSource();

  logsToPhrase(log) {
    if (log.type === "moved") {
      const direction = log.movement > 0 ? "down" : "up";
      return `Moved ${log.value} from position ${
        log.fromIndex + 1
      } to position ${log.toIndex + 1} (${direction} by ${Math.abs(
        log.movement
      )} position${Math.abs(log.movement) === 1 ? "" : "s"})`;
    } else if (log.type === "removed") {
      return `Removed ${log.value} from position ${log.index + 1}`;
    } else if (log.type === "added") {
      return `Added ${log.value} at position ${log.index + 1}`;
    } else if (log.type === "hide") {
      return `Hide ${log.value} at position ${log.index + 1}`;
    } else if (log.type === "unhide") {
      return `Unhide ${log.value} at position ${log.index + 1}`;
    } else {
      return "";
    }
  }

  splitString(input: string): string[] {
    return input.split("_");
  }

  ngOnInit(): void {
    const routeParams = this.router.routerState.snapshot;

    const length = routeParams.url.split("/").length;

    if (routeParams.url.split("/")[length - 1]) {
      let userID = routeParams.url.split("/")[length - 1];
      this.userID = routeParams.url.split("/")[length - 1];

      this.userService.getUserByUserID({ _id: userID }).then((res) => {
        this.userData = res.data;
       
      });
      this.searchesService.getUserSearches({ userID: userID }).then((res) => {
        if (res.data.length > 0) {
          this.selectedSearch = res.data[0]._id;
        }

        this.dataSource = this.dataSourceBuilder.create(res.data);
        this.data = res.data;
        let searchID = this.selectedSearch;
        this.actionService.getTableLogs({ searchID: searchID }).then((res) => {
          res.data.map((element) => {
            element.logsToPhrase = this.logsToPhrase(element);
          });

          this.dataSourceActions = this.dataSourceBuilder.create(res.data);
          this.data2 = res.data;
        });
      });

      this.watchService.getWatchesByUser(userID).then((res) => {
        console.log({res})
        if (res.length > 0) {
          this.selectedWatch = res[0]._id;
        }

        this.dataWatchSource = this.dataSourceBuilder.create(res);
        this.watchData = res;
        // let searchID = this.selectedSearch;
        // this.actionService.getTableLogs({ searchID: searchID }).then((res) => {
        //   res.data.map((element) => {
        //     element.logsToPhrase = this.logsToPhrase(element);
        //   });

        //   this.dataSourceActions = this.dataSourceBuilder.create(res.data);
        //   this.data2 = res.data;
        // });
      });
    }
  }

  updateSort(sortRequest: NbSortRequest): void {
    this.sortColumn = sortRequest.column;
    this.sortDirection = sortRequest.direction;
  }

  getSortDirection(column: string): NbSortDirection {
    if (this.sortColumn === column) {
      return this.sortDirection;
    }
    return NbSortDirection.NONE;
  }

  getShowOn(index: number) {
    const minWithForMultipleColumns = 400;
    const nextColumnStep = 100;
    return minWithForMultipleColumns + nextColumnStep * index;
  }
  toString(data): string {
    return JSON.stringify(data);
  }

  onEditConfirm(event) {

    this.service.updateUser(event.newData).then(() => {
      // location.reload()
      event.confirm.resolve();
    });
  }

  addUser(event) {
    event.newData.password2 = event.newData.password;
    this.service.addUser(event.newData).then(() => {
      // location.reload()
      event.confirm.resolve();
    });
  }
  deleteUser(event) {
    this.service.deleteUser(event.data).then(() => {
      // location.reload()
      event.confirm.resolve();
    });
  }

  onDeleteConfirm(event): void {
    if (window.confirm("Are you sure you want to delete?")) {
      this.service.deleteUser(event.data).then(() => {
        event.confirm.resolve();
      });
    } else {
      event.confirm.reject();
    }
  }

  onCustomAction(event) {
    if (window.confirm("Are you sure you want to export the report?")) {
      this.userService
        .exportSearch({ searchID: event.data._id, userID: this.userID })
        .then((e) => {
         
        })
        .catch((e) => {
         
        });
    } else {
    }
  }

  onRowSelect(event) {
    this.selectedSearch = event.data._id;
    this.actionService
      .getTableLogs({ searchID: this.selectedSearch })
      .then((res) => {
        res.data.map((element) => {
          element.logsToPhrase = this.logsToPhrase(element);
        });
       
        this.dataSourceActions = this.dataSourceBuilder.create(res.data);
        this.data2 = res.data;
      });
    //  this.router.navigate(['/pages/search-details/'+event.data.userID])
  }
  toDate(data) {
    const localTime = new Date(data).toLocaleString("en-US", {
      timeZone: "America/Los_Angeles",
    });
    return localTime;
  }
}
