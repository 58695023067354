import { Injectable } from "@angular/core";
import axios from "axios";
// import axios from 'axios';
import { url } from "./services";
import { AxiosInstanceService } from "./axios-instance.service";

@Injectable({
  providedIn: "root",
})
export class SearchesService {
  constructor(private axiosInstanceService: AxiosInstanceService) {}
  getUserSearches(data) {
    return new Promise<any>((resolve, reject) => {
      let Axios = axios.create({
        baseURL: url,
        timeout: 45000,
      });

      this.axiosInstanceService.axiosInstance
        .post("/user-searches-data", {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "*",
            "Access-Control-Allow-Credentials": "true",
          },
          body: {
            ...data,
          },
          ...data,
        })
        .then((e) => {
          resolve(e);
        })
        .catch((e) => {
          resolve(e);
        });
    });
  }

  getGroupedSearches() {
    return new Promise<any>((resolve, reject) => {
      this.axiosInstanceService.axiosInstance
        .post("/search-grouped", {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "*",
            "Access-Control-Allow-Credentials": "true",
          },
        })
        .then((e) => {
          resolve(e);
        })
        .catch((e) => {
          resolve(e);
        });
    });
  }
  getSearches() {
    return new Promise<any>((resolve, reject) => {
      this.axiosInstanceService.axiosInstance
        .post("/searches", {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "*",
            "Access-Control-Allow-Credentials": "true",
          },
        })
        .then((e) => {
          resolve(e);
        })
        .catch((e) => {
          resolve(e);
        });
    });
  }
  getSearchesByDatesAndClasses() {
    return new Promise<any>((resolve, reject) => {
      this.axiosInstanceService.axiosInstance
        .post("/search-classes", {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "*",
            "Access-Control-Allow-Credentials": "true",
          },
        })
        .then((e) => {
          resolve(e);
        })
        .catch((e) => {
          resolve(e);
        });
    });
  }
}
