import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NbCardModule, NbIconModule, NbInputModule, NbTreeGridModule } from '@nebular/theme';
import { SearchesComponent } from './searches.component';
import { ThemeModule } from '../../../@theme/theme.module';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { SearchesStatsComponent } from './searches-stats/searches-stats.component';
import { SearchDetailsComponent } from './search-details/search-details.component';
import { NbButtonModule,  NbListModule, NbProgressBarModule, NbSelectModule, NbTabsetModule,NbUserModule } from '@nebular/theme';


@NgModule({
  declarations: [
    SearchesComponent,
    SearchDetailsComponent,

  ],
  imports: [
    NbTabsetModule,
    NbCardModule,
    NbTreeGridModule,
    NbIconModule,
    NbInputModule,
    ThemeModule,
    Ng2SmartTableModule,
    NbCardModule,
    CommonModule
  ],
})
export class SearchesModule { }
