import { Component, OnInit } from '@angular/core';
import { RequestsService } from '../../../@core/services/requests.service';

@Component({
  selector: 'ngx-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {
 
  constructor(private requestService: RequestsService) {
    
   }
   users : { name: string, title: string,date:any }[]
  ngOnInit(): void {
    this.requestService.getRequests().then((data)=>{
      console.log((data.data))
      let array = []
      data.data.forEach(element => {
        if(element.requestType == 'data'){
          let data : { name: string, title: string ,date:any} = {
            name : element.userID.firstName + ' ' + element.userID.lastName,
            title : 'Message: '+element.userID.firstName+' has requested access to their data.',
            date: new Date(element.createdAt).toDateString()
          }
          // alert(JSON.stringify(data))
          array.push(data)
        }
        else  if(element.requestType == 'deletion'){
          let data : { name: string, title: string ,date:any} = {
            name : element.userID.firstName + ' ' + element.userID.lastName,
            title : 'Message: '+element.userID.firstName+' has requested deletion to their data.',
            date: new Date(element.createdAt).toDateString()
          }
          // alert(JSON.stringify(data))
          array.push(data)
        }
      });
      this.users=array
      // alert(JSON.stringify(this.users))
    })
  }
  // users: { name: string, title: string }[] = [
  //   { name: 'Carla Espinosa', title: 'Nurse' },
  //   { name: 'Bob Kelso', title: 'Doctor of Medicine' },
  //   { name: 'Janitor', title: 'Janitor' },
  //   { name: 'Perry Cox', title: 'Doctor of Medicine' },
  //   { name: 'Ben Sullivan', title: 'Carpenter and photographer' },
  // ];
}
