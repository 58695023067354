import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import {
  NbSortDirection,
  NbSortRequest,
  NbTreeGridDataSource,
  NbTreeGridDataSourceBuilder,
} from "@nebular/theme";
import { LocalDataSource } from "ng2-smart-table";
import { SmartTableData } from "../../../@core/data/smart-table";
import { UserService } from "../../../@core/mock/users.service";
import { ActionsService } from "../../../@core/services/actions.service";
import { SearchesService } from "../../../@core/services/searches.service";
import { UsersService } from "../../../@core/services/users.service";

interface TreeNode<T> {
  data: T;
  children?: TreeNode<T>[];
  expanded?: boolean;
}

interface FSEntry {
  name: string;
  size: string;
  kind: string;
  items?: number;
}

@Component({
  selector: "searches",
  templateUrl: "./searches.component.html",
  styleUrls: ["./searches.component.scss"],
})
export class SearchesComponent implements OnInit {
  // customColumn = 'type';
  defaultColumns = [
    "userID",
    "firstName",
    "email",
    "selectedClasses",
    "date",
    "imageURL",
  ];
  // allColumns = [ this.customColumn, ...this.defaultColumns ];
  allColumns = [...this.defaultColumns];
  allData: TreeNode<FSEntry>[];
  dataSource: NbTreeGridDataSource<FSEntry>;

  sortColumn: string;
  sortDirection: NbSortDirection = NbSortDirection.NONE;

  constructor(
    private service: UsersService,
    private searchesService: SearchesService,
    private dataSourceBuilder: NbTreeGridDataSourceBuilder<FSEntry>,
    private ActionService: ActionsService,
    private router: Router
  ) {
    this.dataSource = this.dataSourceBuilder.create(this.data);
  }

  data: any;
  settings = {
    actions: {
      add: false,
      edit: false,
      delete: false,
      },


    columns: {
      userID:{
        title:'userID',
        type:'string'
      },
      firstName:{
        title:'firstName',
        type:'string'
      },
      email:{
        title:'email',
        type:'string'
      },
    
      date:{
        title:'date',
        type:'string'
      },
      imageURL:{
        title:'imageURL',
        type: 'html',
        valuePrepareFunction: (picture:string) => { return `<img width="100px" src="${picture}" />`; },
       },
    },
  };

  source: LocalDataSource = new LocalDataSource();

  ngOnInit(): void {
    console.log('searches ehre')
    this.searchesService.getSearches().then((res) => {
      console.log(res.data);
      this.dataSource = this.dataSourceBuilder.create(res.data);
      this.data = res.data;
    }).catch((e)=>{console.log({e})});
  }

  updateSort(sortRequest: NbSortRequest): void {
    this.sortColumn = sortRequest.column;
    this.sortDirection = sortRequest.direction;
  }

  getSortDirection(column: string): NbSortDirection {
    if (this.sortColumn === column) {
      return this.sortDirection;
    }
    return NbSortDirection.NONE;
  }

  getShowOn(index: number) {
    const minWithForMultipleColumns = 400;
    const nextColumnStep = 100;
    return minWithForMultipleColumns + nextColumnStep * index;
  }
  toString(data): string {
    return JSON.stringify(data);
  }

  onEditConfirm(event) {
    // console.log(event)
    this.service.updateUser(event.newData).then(() => {
      // location.reload()
      event.confirm.resolve();
    });
  }

  addUser(event) {
    event.newData.password2 = event.newData.password;
    this.service.addUser(event.newData).then(() => {
      // location.reload()
      event.confirm.resolve();
    });
  }
  deleteUser(event) {
    this.service.deleteUser(event.data).then(() => {
      // location.reload()
      event.confirm.resolve();
    });
  }

  onDeleteConfirm(event): void {
    if (window.confirm("Are you sure you want to delete?")) {
      this.service.deleteUser(event.data).then(() => {
        event.confirm.resolve();
      });
    } else {
      event.confirm.reject();
    }
  }
  onRowSelect(event){
    this.router.navigate(['/pages/search-details/'+event.data.userID])
    // alert(JSON.stringify(event.data))
  }
  toDate(data){

    const localTime = new Date(data).toLocaleString('en-US', { timeZone: 'America/Los_Angeles' });
return localTime
  }
}
