import { Injectable } from "@angular/core";
import axios from "axios";
import { url } from "./services";
import { AxiosInstanceService } from "./axios-instance.service";
@Injectable({
  providedIn: "root",
})
export class UsersService {
  constructor(private axiosInstanceService: AxiosInstanceService) {}
  getUsers() {
    return new Promise<any>((resolve, reject) => {
      this.axiosInstanceService.axiosInstance
        .post("/user-data", {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "*",
            "Access-Control-Allow-Credentials": "true",
          },
        })
        .then((e) => {
          resolve(e);
        })
        .catch((e) => {
          resolve(e);
        });
    });
  }
  getAllUsers() {
    return new Promise<any>((resolve, reject) => {
      this.axiosInstanceService.axiosInstance
        .post("/all-users", {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "*",
            "Access-Control-Allow-Credentials": "true",
          },
        })
        .then((e) => {
          resolve(e);
        })
        .catch((e) => {
          resolve(e);
        });
    });
  }

  exportUser(data) {
    return new Promise<any>((resolve, reject) => {
      let Axios = axios.create({
        baseURL: url,
        timeout: 45000,
      });
      const token = localStorage.getItem('access_token');
      location.href = url + "/export-data/" + data.userID+`?token=${token}`;
      this.axiosInstanceService.axiosInstance
        .get("/export-data/" + data.userID+`?token=${token}`, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "*",
            "Access-Control-Allow-Credentials": "true",
          },
          body: {
            ...data,
          },
          ...data,
        })
        .then((e) => {
          resolve(e);
        })
        .catch((e) => {
          resolve(e);
        });
    });
  }
  exportSearch(data) {
    return new Promise<any>((resolve, reject) => {
      const token = localStorage.getItem("access_token");
      location.href =
        url +
        "/export-report/" +
        data.userID +
        "/" +
        data.searchID +
        `?token=${token}`;
      this.axiosInstanceService.axiosInstance
        .get(
          "/export-report/" +
            data.userID +
            "/" +
            data.searchID +
            `?token=${token}`,
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Headers": "*",
              "Access-Control-Allow-Credentials": "true",
            },
            body: {
              ...data,
            },
            ...data,
          }
        )
        .then((e) => {
          resolve(e);
        })
        .catch((e) => {
          resolve(e);
        });
    });
  }

  updateUser(data) {
    return new Promise<any>((resolve, reject) => {
      this.axiosInstanceService.axiosInstance
        .post("/user-update", {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "*",
            "Access-Control-Allow-Credentials": "true",
          },
          body: {
            ...data,
          },
          ...data,
        })
        .then((e) => {
          resolve(e);
        })
        .catch((e) => {
          resolve(e);
        });
    });
  }
  addUser(data) {
    return new Promise<any>((resolve, reject) => {
      let Axios = axios.create({
        baseURL: url,
        timeout: 45000,
      });

      this.axiosInstanceService.axiosInstance
        .post("/register", {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "*",
            "Access-Control-Allow-Credentials": "true",
          },
          body: {
            ...data,
          },
          ...data,
        })
        .then((e) => {
          resolve(e);
        })
        .catch((e) => {
          resolve(e);
        });
    });
  }

  deleteUser(data) {
    return new Promise<any>((resolve, reject) => {
      let Axios = axios.create({
        baseURL: url,
        timeout: 45000,
      });

      this.axiosInstanceService.axiosInstance
        .post("/user-delete", {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "*",
            "Access-Control-Allow-Credentials": "true",
          },
          body: {
            ...data,
          },
          ...data,
        })
        .then((e) => {
          resolve(e);
        })
        .catch((e) => {
          alert(e);
        });
    });
  }
  getUserByUserID(data) {
    return new Promise<any>((resolve, reject) => {

      this.axiosInstanceService.axiosInstance
        .post("/get-user", {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "*",
            "Access-Control-Allow-Credentials": "true",
          },
          body: {
            ...data,
          },
          ...data,
        })
        .then((e) => {
          resolve(e);
        })
        .catch((e) => {
          alert(e);
        });
    });
  }
}
