import { Injectable } from '@angular/core';
import axios from 'axios';
import { url } from './services';
import { AxiosInstanceService } from './axios-instance.service';

@Injectable({
  providedIn: 'root'
})
export class ActionsService {

  constructor(private axiosInstanceService:AxiosInstanceService) { }
  getActions () {
    return new Promise<any>((resolve, reject) => {
  
  this.axiosInstanceService.axiosInstance.post("/actions", {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "*",
      "Access-Control-Allow-Credentials": "true",
    }
  })
    .then((e) => {resolve(e)})
    .catch((e) => {resolve(e)});
  })
}
getUserActions (data) {
  return new Promise<any>((resolve, reject) => {

    this.axiosInstanceService.axiosInstance.post("/user-actions", {
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers": "*",
    "Access-Control-Allow-Credentials": "true",
  },
  body:{
    ...data
  },
  ...data
})
  .then((e) => {resolve(e)})
  .catch((e) => {resolve(e)});
})
}
getTableLogs (data) {
  return new Promise<any>((resolve, reject) => {


    this.axiosInstanceService.axiosInstance.post("/get-table-logs", {
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers": "*",
    "Access-Control-Allow-Credentials": "true",
  },
  body:{
    ...data
  },
  ...data
})
  .then((e) => {resolve(e)})
  .catch((e) => {resolve(e)});
})
}
}
