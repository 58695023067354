import { Component, OnInit } from "@angular/core";
import { RequestsService } from "../../../@core/services/requests.service";

@Component({
  selector: "ngx-deletion-requests",
  templateUrl: "./deletion-requests.component.html",
  styleUrls: ["./deletion-requests.component.scss"],
})
export class DeletionRequestsComponent implements OnInit {
  constructor(private requestDataService: RequestsService) {}
  data: any;
  settings = {
    actions: {
      add: false,
      edit: false,
      delete: false,
    },

    columns: {
      id: {
        title: "userID",
        type: "string",
      },
      firstName: {
        title: "firstName",
        type: "string",
      },
      email: {
        title: "email",
        type: "string",
      },

      createdAt: {
        title: "date",
        type: "date",
      },
    },
  };
  ngOnInit(): void {
    this.requestDataService.getDeletionRequests().then((data) => {
      let newData = data.data.map((res) => ({ ...res, ...res.userID }));
      console.log(newData);
      this.data = newData;
    });
  }
}
