import { Component } from '@angular/core';
import { Router, Routes } from '@angular/router';
import { NbAuthService, NbAuthJWTToken } from '@nebular/auth';
import { AuthService } from '../../../@core/services/auth.service';
import { AxiosInstanceService } from '../../../@core/services/axios-instance.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  constructor(private authService: AuthService,private route:  Router,private axiosInstanceService : AxiosInstanceService ) {}
  email: string
  password: string
  message=''
  login() {
  
  }
  onSubmit(event){
    event.preventDefault(); // Prevent the form from submitting in the default way

    const formData = new FormData(event.target as HTMLFormElement); // Get the form data using the Event object
  
    // Access the form fields using the FormData object
    const email = this.email
    const password = this.password

    let authData = {email:email,password:password}
    this.authService.login(authData).then((data)=>{
   
      localStorage.setItem('access_token',data.data.token)
      localStorage.setItem('sessionID',data.data.sessionID)
      
    if (data.data.token) {
      this.axiosInstanceService.axiosInstance.defaults.headers.common['Authorization'] = `${data.data.token}`;
    }
    if (data.data.sessionID) {
      this.axiosInstanceService.axiosInstance.defaults.headers.common['SessionID'] = data.data.sessionID;
    }
      this.route.navigate(['pages/users'])
    })
  
  }
  setDefaultHeaders() {
    const token = localStorage.getItem('access_token');
    const sessionID = localStorage.getItem('sessionID');

  }
}
