import { Injectable } from '@angular/core';
import { AxiosInstanceService } from './axios-instance.service';

@Injectable({
  providedIn: 'root'
})
export class WatchesService {
  constructor(private axiosInstanceService: AxiosInstanceService) { }

  createWatch(data: { userID: string; imageURL: string; frequency: string }) {
    return this.axiosInstanceService.axiosInstance.post('/watch', data)
      .then(response => response.data)
      .catch(error => Promise.reject(error.response.data));
  }

  updateWatchFrequency(id: string, frequency: string) {
    return this.axiosInstanceService.axiosInstance.post(`/watch-update-frequency/${id}`, { frequency })
      .then(response => response.data)
      .catch(error => Promise.reject(error.response.data));
  }

  deleteWatch(id: string) {
    return this.axiosInstanceService.axiosInstance.delete(`/watch/${id}`)
      .then(response => response.data)
      .catch(error => Promise.reject(error.response.data));
  }

  getWatchesByUser(userID: string) {
    return this.axiosInstanceService.axiosInstance.post(`/watch/user`,{
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*",
        "Access-Control-Allow-Credentials": "true"
      },
      userID
    })
      .then(response => response.data)
      .catch(error => Promise.reject(error.response.data));
  }

  getAllWatches() {
    return this.axiosInstanceService.axiosInstance.post('/watches',{
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*",
        "Access-Control-Allow-Credentials": "true"
      }
    })
    .then(response => response.data)
    .catch(error => Promise.reject(error.response.data));
  }
}
