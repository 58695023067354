<div class="row   ">
    <div class="col-3   text-secondary align-items-stretch">
        <nb-card>
            <nb-card-header>User Details</nb-card-header>
            <nb-card-body class=" text-secondary ">
                First Name: {{userData?.firstName}}
                <br />
                Last Name: {{userData?.lastName}}
                <br />
                Email: {{userData?.email}}
                <br />
                Phone: {{userData?.phone}}
            </nb-card-body>
        </nb-card>
    </div>
    <div class="col-3   text-secondary align-items-stretch ">
        <nb-card>
            <nb-card-header>User Activity</nb-card-header>
            <nb-card-body class=" text-secondary ">
                Joined: {{toDate(userData?.date)}}
                <br />
                <!-- Last Online: {{toDate(userData?.date)}}
                <br />
                <br />
                <br /> -->
            </nb-card-body>
        </nb-card>
    </div>
    <div *ngIf="userData?.user?.subscription" class="col-6   text-secondary align-items-stretch ">
        <nb-card>
            <nb-card-header>User Subscription</nb-card-header>
            <nb-card-body class=" text-secondary ">
                <!-- Subscription ID: {{userData?.user?.subscription?.subscriptionID}}
        <br/> -->
                Subscription Type: {{splitString(userData?.user?.subscription?.type)[0]}}
                <br />
                Subscription Plan: {{splitString(userData?.user?.subscription?.type)[1]}}
                <br />
                Subscribed At: {{toDate(userData?.user?.subscription?.subscribedAt)}}
                <br />
                Subscription Expires: {{toDate(userData?.user?.subscription?.subscriptionExpires)}}
            </nb-card-body>
        </nb-card>
    </div>
    <div *ngIf="!userData?.user?.subscription" class="col-6   text-secondary align-items-stretch ">
        <nb-card>
            <nb-card-header>Not Subscribed</nb-card-header>

        </nb-card>
    </div>
</div>
<nb-card>
    <nb-tabset>
      <nb-tab tabTitle="Searches">
        <ng-template nbTabContent>
            <div class="row">
                <div class="col-6">
                    <nb-card>
                        <nb-card-body class=" text-secondary">
                            <ng2-smart-table  (custom)="onCustomAction($event)" [settings]="settings" [source]="data" (userRowSelect)="onRowSelect($event)">
                            </ng2-smart-table>
                        </nb-card-body>
                    </nb-card>
                </div>
                <div class="col-6">
                    <nb-card>
                        <nb-card-body class=" text-secondary">
                            <ng2-smart-table [settings]="settings2" [source]="data2">
                            </ng2-smart-table>
                        </nb-card-body>
                    </nb-card>
                </div>
            </div>
        </ng-template>
      </nb-tab>
      <nb-tab tabTitle="Watches">
        <ng-template nbTabContent>
            <div class="row">
                <div class="col-6">
                    <nb-card>
                        <nb-card-body class=" text-secondary">
                            <ng2-smart-table  (custom)="onCustomAction($event)" [settings]="watchSettings" [source]="watchData" (userRowSelect)="onRowSelect($event)">
                            </ng2-smart-table>
                        </nb-card-body>
                    </nb-card>
                </div>
                <div class="col-6">
                    <nb-card>
                        <nb-card-body class=" text-secondary">
                            <ng2-smart-table [settings]="settings2" [source]="data2">
                            </ng2-smart-table>
                        </nb-card-body>
                    </nb-card>
                </div>
            </div>
        </ng-template>
      </nb-tab>
      <!-- Add more tabs as needed -->
    </nb-tabset>
  </nb-card>
  
