import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NbCardModule, NbIconModule, NbInputModule, NbListComponent, NbListModule, NbTreeGridModule, NbUserModule } from '@nebular/theme';

import { ThemeModule } from '../../../@theme/theme.module';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { NotificationsComponent } from './notifications.component';
import { NbUser } from '@nebular/auth';



@NgModule({
  declarations: [
    NotificationsComponent
  ],
  imports: [
    NbUserModule,
    NbListModule,
    NbCardModule,
    NbTreeGridModule,
    NbIconModule,
    NbInputModule,
    ThemeModule,
    Ng2SmartTableModule,
    NbCardModule,
    CommonModule
  ],
})
export class NotificationsModule { }


