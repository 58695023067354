import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NbCardModule, NbInputModule } from '@nebular/theme';
import { TreeGridComponent } from './tree-grid.component';





@NgModule({
  declarations: [TreeGridComponent],
  imports: [
    CommonModule,
    NbCardModule,
    NbInputModule
  ],
  exports:[

  ]
})
export class TreeGridModule { }
