import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import axios from 'axios';
import { url } from './services';
import { AxiosInstanceService } from './axios-instance.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private jwtHelper: JwtHelperService;
  constructor(private axiosInstanceService:AxiosInstanceService) {  this.jwtHelper = new JwtHelperService();}
  login(data){

    return new Promise<any>((resolve, reject) => {
     
  
     this.axiosInstanceService.axiosInstance.post("/admin-login",{  
      email:data.email,
      password:data.password,
      body:{
        email:data.email,
        password:data.password
      },
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*",
        "Access-Control-Allow-Credentials": "true",
      },
    })
      .then((e) => {resolve(e)})
      .catch((e) => {resolve(e)});
  })
  }
  public isAuthenticated(): boolean {
    const token = localStorage.getItem('access_token');
    return !this.jwtHelper.isTokenExpired(token);
  }
}
