import { Component, OnInit } from '@angular/core';
import { RequestsService } from '../../../@core/services/requests.service';
import { SubscriptionsService } from '../../../@core/services/subscriptions.service';

@Component({
  selector: 'ngx-subscriptions',
  templateUrl: './subscriptions.component.html',
  styleUrls: ['./subscriptions.component.scss']
})
export class SubscriptionsComponent implements OnInit {
  constructor(private requestDataService: SubscriptionsService) {}
  data: any;
  settings = {
    actions: {
      add: false,
      edit: false,
      delete: false,
    },

    columns: {
      id: {
        title: "User ID",
        type: "string",
      },
      firstName: {
        title: "First Name",
        type: "string",
      },
      email: {
        title: "Email",
        type: "string",
      },
      type: {
        title: "Type",
        type: "string",
      },
      subscriptionExpires: {
        title: "Subscription Expires",
        type: "text",
       // valuePrepareFunction: (subscriptionExpires:string) => { return `<p style="font-size:4px">${subscriptionExpires}</p>`; },
      },
      status: {
        title: "Status",
        type: "string",
      },
      subscribedAt: {
        title: "Subscribed At",
        type: "string",
      },

      stripeSubscriptionID: {
        title: "Subscription ID",
        type: "string",
      },
    },
  };
  ngOnInit(): void {
    this.requestDataService.getDataSubscriptions().then((data) => {
      let newData = data.data.map((res) => ({ ...res, ...res.userID }));
      console.log(newData);
      this.data = newData;
    });
  }
}
